<template>
  <div class="main container product-page">
    <Breadcrumb :list="breadcrumbList" />
    <h2 class="title">产品介绍</h2>
    <p class="animate__animated animate__fadeIn">
      ShuangzhaoDB
      是分布式内存数据库，最大的特点就是快，能够一站式实现数据加载、计算、存储的需求，降低总成本并简化架构复杂性。全内存计算通常存放G~T级数据量，多用于热数据或温数据处理及存储。
    </p>
    <ul class="introduction">
      <li v-for="item in introductionList" :key="item.id">
        <router-link :to="item.path">
          <i class="iconfont" :class="item.icon" />
          <h3 class="title">{{ item.title }}</h3>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import "animate.css";
export default {
  name: "Product",
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumbList: [
        { id: 1, path: "/", label: "ShuangzhaoDB" },
        { id: 2, path: "", label: "产品" },
      ],
      introductionList: [
        {
          id: 1,
          icon: "icon-linian",
          title: "设计理念",
          path: "/product/design-concept",
        },
        {
          id: 2,
          icon: "icon-wendangchaxun",
          title: "特性",
          path: "/product/character",
        },
        {
          id: 3,
          icon: "icon-gengduoyingyong",
          title: "应用场景",
          path: "/product/application-scene",
        },
        {
          id: 4,
          icon: "icon-yijiaruduibi",
          title: "产品对比",
          path: "/product/comparison",
        },
        {
          id: 5,
          icon: "icon-jiagou",
          title: "架构",
          path: "/product/architecture",
        },
        {
          id: 6,
          icon: "icon-anli",
          title: "案例",
          path: "/product/case",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.product-page {
  margin: 50px auto;
  h2.title {
    color: @themeRed;
    font-size: 40px;
    line-height: 40px;
    padding: 50px 0;
  }
  .introduction {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 100px 0;
    li {
      &:hover .iconfont {
        transform: scale(1.2);
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover .title {
          color: @themeRed;
        }
        .iconfont {
          font-size: 80px;
          color: @themeRed;
          transition: all 0.5s linear;
        }
        .title {
          font-size: 26px;
          color: @themeBlue;
        }
      }
    }
  }
}

@media screen and(max-width:991px) {
  .product-page {
    margin: 20px auto;
    h2.title {
      font-size: 26px;
      padding: 15px 0;
    }
    .introduction {
      align-items: flex-start;
      padding: 30px 0;
      li {
        width: 33%;
        padding: 0 5px;
        a {
          .iconfont {
            font-size: 50px;
          }
          .title {
            font-size: 20px;
            text-decoration: underline;
          }
          &:hover .title {
            color: @themeBlue;
          }
        }
      }
    }
  }
}
</style>